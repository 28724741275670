<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'

export default {
  name: 'qr-workspace-unavailable',
  components: {
    BaseButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    onHide: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    hide() {
      modal.hide()
      this.onHide()
    },
  },
}
</script>

<template>
  <qr-modal type="error">
    <template #title>
      <span>
        {{ title }}
      </span>
    </template>
    <p class="mb-2.5 text-center">
      {{ message }}
    </p>
    <template #footer>
      <base-button class="mt-[30px] w-full" @click="hide">Got it</base-button>
    </template>
  </qr-modal>
</template>
